<script setup lang="ts">
import { useRouter, useRoute } from 'vue-router';
import {
  FolderIcon,
  HomeIcon,
  PencilIcon,
  SwitchHorizontalIcon,
  ChartBarIcon,
  FlagIcon,
  UploadIcon,
  BadgeCheckIcon,
  DocumentReportIcon, ReceiptTaxIcon, CalculatorIcon,
  ChartSquareBarIcon,
  CogIcon,
  KeyIcon,
  LockClosedIcon,
} from '@heroicons/vue/outline';
import { useI18n } from 'vue-i18n';
import { computed } from 'vue';
import getRouteRoleRequirements from '@/utils/helpers/getRouteRoleRequirements';
import useCurrentUser from '@/utils/composables/useCurrentUser/useCurrentUser';
import logoSquare from '@/assets/logo-square.svg';
import { featureFlags } from '@/lib/flagsmith';
import MlHelpMenu from './MlHelpMenu.vue';
import MlProfileMenu from './MlProfileMenu.vue';
import MlPageNavItem from './MlPageNavItem.vue';
import AtNavNotification from './AtNavNotification.vue';

const emit = defineEmits<TEmits>();
type TEmits = {
  (e: 'itemClick'): void
  (e: 'showImpersonateBar'): void
  (e: 'showRestrictionsBar'): void
}

const router = useRouter();
const route = useRoute();
const { t } = useI18n();
const { isCodioUser, currentUser, isAdminOrSuperAdmin, isAuditor } = useCurrentUser();

// const isHelpMenuOpen = ref(false);
const isAdminPath = computed(() => route.path.includes('/admin/'));
const isModulesPath = computed(() => route.path.includes('/modules'));

const { getStartedSteps, getStartedStepsCompleted, isGetStartedCompleted, activeEntity } = useCurrentUser();

const navigation = computed(() => [
  { label: t('Get Started'), routeName: 'getStarted', roles: getRouteRoleRequirements(router, '/get-started'), icon: BadgeCheckIcon, isShown: !isGetStartedCompleted.value },
  { label: t('Home'), routeName: 'home', roles: getRouteRoleRequirements(router, '/home'), icon: HomeIcon, isShown: true },
  { label: t('Library'), routeName: 'evidence', roles: getRouteRoleRequirements(router, '/evidence'), icon: UploadIcon, isShown: featureFlags.ai },
  { label: t('Data entry'), routeName: 'projects', roles: getRouteRoleRequirements(router, '/projects'), icon: PencilIcon, isShown: true },
  { label: t('Upload'), routeName: 'dataEntryUpload', roles: getRouteRoleRequirements(router, '/data-entry-upload'), icon: UploadIcon, isShown: true },
  { label: t('Approval'), routeName: 'approval', roles: getRouteRoleRequirements(router, '/approval'), icon: SwitchHorizontalIcon, isShown: true },
  { label: t('Statistics'), routeName: 'statistics', roles: getRouteRoleRequirements(router, '/statistics'), icon: ChartBarIcon, isShown: true },
]);

const navigationItems = computed(() => navigation.value.filter((item) => item.isShown));

// const topNavigationItems = computed(() => [
//   { label: t('Workspaces'), routeName: 'home', roles: getRouteRoleRequirements(router, '/home'), icon: HomeIcon, path: '/home' },
//   { label: t('Modules'), routeName: 'modules', roles: getRouteRoleRequirements(router, '/modules'), icon: ReceiptTaxIcon, path: '/modules' },
//   { label: t('Admin workspace'), routeName: 'adminHome', roles: getRouteRoleRequirements(router, '/admin/home'), icon: HomeIcon, path: '/admin' },
// ]);

const adminNavigationItems = computed(() => [
  { label: t('Home'), routeName: 'adminHome', roles: getRouteRoleRequirements(router, '/admin/home/overview'), icon: HomeIcon },
  { label: t('Statistics'), routeName: 'adminStatistics', roles: getRouteRoleRequirements(router, '/admin/statistics/overview'), icon: ChartBarIcon },
  { label: t('Reports'), routeName: 'adminReports', roles: getRouteRoleRequirements(router, '/admin/reports/overview'), icon: DocumentReportIcon },
  { label: t('Audit'), routeName: 'adminAudit', roles: getRouteRoleRequirements(router, '/admin/audit'), icon: FolderIcon },
  { label: t('Goals'), routeName: 'adminGoals', roles: getRouteRoleRequirements(router, '/admin/goals'), icon: FlagIcon, isShown: featureFlags.newGoals },
]);

const modulesNavigationItems = computed(() => [
  { label: t('EU Taxonomy'), routeName: 'taxonomy', roles: getRouteRoleRequirements(router, '/modules/taxonomy'), icon: ReceiptTaxIcon },
  { label: t('Carbon calculator'), routeName: 'calculators', roles: getRouteRoleRequirements(router, '/modules/calculators/carbonFootprintCalculator'), icon: CalculatorIcon },
  { label: t('Double Materiality Assessment'), routeName: 'doubleMateriality', roles: getRouteRoleRequirements(router, '/modules/double-materiality'), icon: ChartSquareBarIcon },
]);

// const { ADMIN, SUPERADMIN, AUDITOR } = UserRole;

// const sortedEntities = computed(() => sortBy([...currentUser.value?.entities ?? []], [(entity) => entity._id !== activeEntity.value?._id]));
// const assignedLocations = computed(() => [...new Set([
//   ...currentUser.value?.assignments.subcategories?.map((subcategory) => subcategory.location) ?? [],
//   ...currentUser.value?.assignments.datapointtypes.map((subcategory) => subcategory.location) ?? [],
// ])]);

// const sortedLocations = computed(
//   () => sortBy([
//     ...([ADMIN, SUPERADMIN, AUDITOR].includes(currentUser.value?.role ?? UserRole.NONE)
//       ? currentUser.value?.entity.locations
//       : assignedLocations.value
//     ) ?? []], [(location) => location._id !== route.params.project],

//   ));

const logoRouteName = computed(() => {
  if (!isAdminPath.value && !isModulesPath.value && (isAdminOrSuperAdmin.value || isAuditor.value)) {
    return 'adminHome';
  }
  if (!isModulesPath.value) {
    return 'taxonomy';
  }
  return 'home';
});
</script>

<template>
  <div
    id="page-nav"
    class="z-[100] flex self-start bg-gray-100 max-h-screen"
    data-cy="navigation"
  >
    <div
      id="page-nav-header"
      class="fixed right-0 z-200 left-[65px]"
    />
    <div
      class="flex h-screen flex-col w-[50px] border-r border-gray-200 pt-3 pb-5 text-sm font-medium delay-500 hover:w-[207px] group transition-all duration-150 ease-out"
    >
      <router-link
        :to="{ name: logoRouteName }"
        class="flex items-center px-2"
        @click="emit('itemClick')"
      >
        <img
          class="h-10"
          :src="logoSquare"
          alt="Codio Impact Logo"
        >
        <div class="invisible delay-500 group-hover:visible font-semibold px-2 line-clamp-2 text-wrap">
          Codio Impact
          <div v-if="isAdminPath" class="text-xs font-normal">
            {{ t('Admin Workspace') }}
          </div>
          <div v-else-if="isModulesPath" class="text-xs font-normal">
            {{ t('Modules') }}
          </div>
          <div v-if="!isAdminPath && !isModulesPath" class="text-xs font-normal">
            {{ activeEntity?.name }}
          </div>
        </div>
      </router-link>

      <nav class="flex flex-col w-full justify-between mt-3">
        <div v-if="isAdminPath" class="flex flex-col items-start">
          <MlPageNavItem
            v-for="{
              routeName, roles, icon, label,
            } in adminNavigationItems"
            :key="routeName"
            v-rolefrom="roles"
            :routeName="routeName"
            :icon="icon"
            :label="label"
            @itemClick="emit('itemClick')"
          />
        </div>
        <div v-else-if="isModulesPath" class="flex flex-col items-start">
          <MlPageNavItem
            v-for="{
              routeName, roles, icon, label,
            } in modulesNavigationItems"
            :key="routeName"
            v-rolefrom="roles"
            :routeName="routeName"
            :icon="icon"
            :label="label"
            @itemClick="emit('itemClick')"
          />
        </div>
        <div v-else class="flex flex-col items-start">
          <MlPageNavItem
            v-for="{
              routeName, roles, icon, label,
            } in navigationItems"
            :key="routeName"
            v-rolefrom="roles"
            :routeName="routeName"
            :icon="icon"
            :label="label"
            @itemClick="emit('itemClick')"
          >
            <template #precontent>
              <AtNavNotification
                v-if="routeName === 'getStarted'"
                :total="getStartedSteps"
                data-cy="AtNavNotification"
                :current="getStartedStepsCompleted"
              />
            </template>
          </MlPageNavItem>
        </div>
      </nav>
      <div class="mt-auto flex flex-col">
        <div
          v-if="isAdminPath"
          class="flex flex-col items-start w-full"
        >
          <MlPageNavItem
            routeName="adminSettings"
            :icon="CogIcon"
            :label="t('Settings')"
            @itemClick="emit('itemClick')"
          />
        </div>

        <div v-else class="flex flex-col items-start">
          <MlPageNavItem
            routeName="settings"
            :icon="CogIcon"
            :label="t('Settings')"
            @itemClick="emit('itemClick')"
          />
        </div>
        <MlPageNavItem
          v-if="isCodioUser"
          :icon="KeyIcon"
          activeClass="text-gray-900"
          :label="t('Impersonate')"
          isDelayed
          @click.stop="emit('showImpersonateBar')"
        />
        <MlPageNavItem
          v-if="isCodioUser"
          :icon="LockClosedIcon"
          activeClass="text-gray-900"
          :label="t('Restrictions')"
          @click.stop="emit('showRestrictionsBar')"
        />
        <MlHelpMenu>
          <span class="group-hover:flex font-normal invisible delay-500 group-hover:visible">
            {{ t('Help') }}
          </span>
        </MlHelpMenu>
        <MlProfileMenu class="mt-4 ml-1 px-2">
          <div class="text-sm group-hover:flex justify-start w-[150px] invisible delay-500 group-hover:visible">
            <p class="leading-4 font-normal text-start ml-2 line-clamp-2">
              {{ currentUser?.firstName }}
              {{ currentUser?.lastName }}
              <span class="font-light truncate" :title="currentUser?.email">
                {{ currentUser?.email }}
              </span>
            </p>
          </div>
        </MlProfileMenu>
      </div>
    </div>
  </div>
</template>
